import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Global/Layout'
import Homepage from '../../components/Homepage'
import {
  GlobalStateContext,
  GlobalDispatchContext
} from '../../context/GlobalContextProvider'
const locale = 'en-CA'

export const query = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          title
          bodyText0
          firstCta {
            internal {
              content
            }
          }
          headingText0
          buttonText2 {
            internal {
              content
            }
          }
          headingText1
          workCardsTitle
          workCardsBody {
            internal {
              content
            }
          }
          workCardsIcon {
            file {
              url
            }
          }
          buttonText3 {
            internal {
              content
            }
          }
          headingText2
          headingText3
          trademarkDifferences {
            internal {
              content
            }
          }
          heirlumeAdvantageTable {
            internal {
              content
            }
          }
          tableLogo {
            file {
              url
            }
          }
          clearbancCardText {
            internal {
              content
            }
          }
          clearcoLogo {
            file {
              url
            }
          }
          quotesText {
            internal {
              content
            }
          }
          quotesImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          headingText4
          partnersNetworkLogo {
            file {
              url
              fileName
            }
          }
          registerCardText {
            internal {
              content
            }
          }
          headingText5
          faqText {
            internal {
              content
            }
          }
          searchCardText {
            internal {
              content
            }
          }
          searchCardImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          headingText6
          featuredLogos {
            file {
              url
              fileName
            }
          }
          headingText7
          qualifiedLogos {
            file {
              url
              fileName
            }
          }
          headingText8
          headingText9
          servicesCardTitle
          servicesCardBody
          servicesCardImage {
            file {
              url
            }
          }
          servicesCardButton {
            internal {
              content
            }
          }
          protectCardText {
            internal {
              content
            }
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

export default function HomepageView(props) {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { allContentfulHomePage } = props.data

  useEffect(() => {
    dispatch({ type: 'SET_LOCALE', payload: { locale: locale } })
  }, [state.locale])

  const homeData = allContentfulHomePage?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node

  return (
    <Layout
      locale={state.locale}
      title={JSON.parse(homeData?.seoContent.internal.content).title}
      desc={JSON.parse(homeData?.seoContent.internal.content).desc}
      url=''
    >
      <Homepage locale={state.locale} homeData={homeData} />
    </Layout>
  )
}
